import React, { useRef, useState, useEffect } from "react";
import { Button, Tabs, Tab, Col, Nav, Row, Form, Container, Modal } from "react-bootstrap";
import SignatureCanvas, { fromDataURL } from "react-signature-canvas";
import html2canvas from "html2canvas";
import * as styles from "./styles.module.css";
import { useStoreActions } from "easy-peasy";

const Signature = (props) => {
  const [isShowModal, setIsShowModal] = useState(false);
  const [singatureInputFieldValue, setSingatureInputFieldValue] = useState("");

  const updOnPdfInputValue = useStoreActions((actions) => actions.updOnPdfInputValue);
  const [sidebarState, setSidebarState] = useState(true);
  const [signatureBg, setSignatureBg] = useState({ backgroundImage: "url()" });
  const [tabKey, setTabKey] = useState("sign");

  const inputSignatureField = useRef(null);
  const currentSignInSpan = useRef(null);
  const signatureCanvas = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    }, 300);
  }, [tabKey]);

  const saveSign = (dataImgBase64) => {
    updOnPdfInputValue({ i: props.elProps.elData.id, inputValue: dataImgBase64 });
    // save to user profile or db
  };
  const clearSign = () => {
    // console.log("Clear S");
    if (signatureCanvas.current) {
      signatureCanvas.current.clear();
    }
    setSignatureBg({ backgroundImage: "url()" });
    setSingatureInputFieldValue("");
    updOnPdfInputValue({ i: props.elProps.elData.id, inputValue: "" });
  };

  async function insertSign(e) {
    console.log("insert");
    let dataImgBase64 = "";

    if (tabKey == "type") {
      const canvas = await html2canvas(currentSignInSpan.current, {
        backgroundColor: null,
      });
      // console.log(canvas);
      dataImgBase64 = canvas.toDataURL();
      if (dataImgBase64 != "" && currentSignInSpan.current.innerHTML != "") {
        signatureCanvas.current.fromDataURL(dataImgBase64);
      }
    } else if (tabKey == "sign") {
      dataImgBase64 = signatureCanvas.current.toDataURL();
    }

    console.log(dataImgBase64);
    setSignatureBg({ backgroundImage: `url(${dataImgBase64})` });
    saveSign(dataImgBase64);
    setIsShowModal(false);
  }

  return (
    <div>
      {/* Open Signature Modal sign doc and insert result to preview box */}
      <div className={styles.signaturePreview} style={signatureBg}></div>
      <Button variant="primary" onClick={() => setIsShowModal(true)} tabIndex={props.elProps.tabIndex}>
        Click to Sign
      </Button>
      <Button variant="outline-secondary" className="m-1" onClick={clearSign} tabIndex={props.elProps.tabIndex}>
        Clear
      </Button>

      <Modal centered show={isShowModal} onHide={() => setIsShowModal(false)} animation={false}>
        <Modal.Header closeButton>
          <Modal.Title>Add your signature</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.signatureModalBody}>
          <Tabs defaultActiveKey={tabKey} onSelect={(k) => setTabKey(k)} id="signature-tab" className="mb-3">
            <Tab eventKey="sign" title="Sign">
              <div className={styles.sigContainer}>
                <SignatureCanvas
                  ref={signatureCanvas}
                  penColor="black"
                  // backgroundColor="rgba(1,1,1,0.1)"
                  canvasProps={{
                    height: 200,
                    // width: "100%",
                    className: styles.sigPad,
                  }}
                />
              </div>
            </Tab>
            <Tab eventKey="type" title="Type it in">
              <Form.Group controlId={`form_input_signature`}>
                <Form.Label className="" />
                {/* html2canvas not working properly with input fields, 
                that is why we update span element with latest value from input 
                and print it as signature with slyles from css */}
                <div className={styles.signatureWrapper}>
                  <span className={styles.signatureSpan} style={{ padding: "10px" }} ref={currentSignInSpan}>
                    {singatureInputFieldValue}
                  </span>
                </div>
                <Form.Control
                  ref={inputSignatureField}
                  className={styles.inputSignatureField}
                  maxLength="25"
                  value={singatureInputFieldValue}
                  // size="lg"
                  type="input"
                  name={`signature_res`}
                  onChange={(e) => setSingatureInputFieldValue(e.target.value)}
                  placeholder="type  here"
                />
                <Form.Control.Feedback type="invalid">required</Form.Control.Feedback>
              </Form.Group>
            </Tab>
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="outline-secondary" className="m-1" onClick={clearSign}>
            Clear
          </Button>
          <Button variant="outline-primary" className="m-1" onClick={insertSign}>
            Insert
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Open Signature Modal sign doc and insert result to preview box */}
    </div>
  );
};

Signature.defaultProps = {
  elProps: {
    formBuilder: false,
    formEl: '{fieldType: "signature", formElementData: {…}, i: "…}',
    elData: '{addToPdf: true, content: "Signature", fieldType: "…}',
    handleChange: "ƒ handleChange() {}",
  },
};

export default Signature;
